import _ from 'lodash'
import { getLogoutUrl } from '~/utils/auth0/user/methods'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { data: user, signOut } = useAuth()

  if (!_.has(user.value, 'error')) {
    return
  }

  /*
   * Déconnecte l'utilisateur s'il est introuvable
   * Utile lorsqu'il est supprimé d'investhub
   */
  if (user.value.error === 'user_not_found') {
    await signOut({ callbackUrl: getLogoutUrl() })
  }
})
