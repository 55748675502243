export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig()

  // Exemple URL du type : https://wealth-portal.lndo.site/https://auth-dev.eurazeo.com ou bien https://wealth-portal.lndo.site/partenaire/https://auth-dev.eurazeo.com
  // to.fullPath contiendra  /https://auth-dev.eurazeo.com
  if (to.fullPath.includes('/' + config.public.eurazeo.auth0.customIssuer)) {
    console.log('wrong url redirect')

    return navigateTo(from.fullPath)
  }
})
