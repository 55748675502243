export function getLogoutUrl(returnTo?: string) {
  const config = useRuntimeConfig()

  const issuer = config.public.eurazeo.auth0.customIssuer
  const clientId = config.public.eurazeo.auth0.investor.clientId

  if (!returnTo) {
    returnTo = config.public.baseUrl
  }

  return `${issuer}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`
}
