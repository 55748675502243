import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAkLink, LazyBlocksBanner, LazyBlocksColumns, LazyBlocksHero, LazyBlocksHighlightFunds, LazyBlocksInterlocutors, LazyBlocksKeyFigures, LazyBlocksMedia, LazyBlocksText, LazyBlocksThumbnails, LazyBlocksTitle, LazyBlocksZigzag, LazyUAccordion, LazyUAlert, LazyUAvatar, LazyUAvatarGroup, LazyUBadge, LazyUButton, LazyUButtonGroup, LazyUCarousel, LazyUChip, LazyUDropdown, LazyUIcon, LazyUKbd, LazyULink, LazyUMeter, LazyUMeterGroup, LazyUProgress, LazyUCheckbox, LazyUForm, LazyUFormGroup, LazyUInput, LazyUInputMenu, LazyURadio, LazyURadioGroup, LazyURange, LazyUSelect, LazyUSelectMenu, LazyUTextarea, LazyUToggle, LazyUTable, LazyUCard, LazyUContainer, LazyUDivider, LazyUSkeleton, LazyUBreadcrumb, LazyUCommandPalette, LazyUCommandPaletteGroup, LazyUHorizontalNavigation, LazyUPagination, LazyUTabs, LazyUVerticalNavigation, LazyUContextMenu, LazyUModal, LazyUModals, LazyUNotification, LazyUNotifications, LazyUPopover, LazyUSlideover, LazyUSlideovers, LazyUTooltip, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AkLink", LazyAkLink],
["BlocksBanner", LazyBlocksBanner],
["BlocksColumns", LazyBlocksColumns],
["BlocksHero", LazyBlocksHero],
["BlocksHighlightFunds", LazyBlocksHighlightFunds],
["BlocksInterlocutors", LazyBlocksInterlocutors],
["BlocksKeyFigures", LazyBlocksKeyFigures],
["BlocksMedia", LazyBlocksMedia],
["BlocksText", LazyBlocksText],
["BlocksThumbnails", LazyBlocksThumbnails],
["BlocksTitle", LazyBlocksTitle],
["BlocksZigzag", LazyBlocksZigzag],
["UAccordion", LazyUAccordion],
["UAlert", LazyUAlert],
["UAvatar", LazyUAvatar],
["UAvatarGroup", LazyUAvatarGroup],
["UBadge", LazyUBadge],
["UButton", LazyUButton],
["UButtonGroup", LazyUButtonGroup],
["UCarousel", LazyUCarousel],
["UChip", LazyUChip],
["UDropdown", LazyUDropdown],
["UIcon", LazyUIcon],
["UKbd", LazyUKbd],
["ULink", LazyULink],
["UMeter", LazyUMeter],
["UMeterGroup", LazyUMeterGroup],
["UProgress", LazyUProgress],
["UCheckbox", LazyUCheckbox],
["UForm", LazyUForm],
["UFormGroup", LazyUFormGroup],
["UInput", LazyUInput],
["UInputMenu", LazyUInputMenu],
["URadio", LazyURadio],
["URadioGroup", LazyURadioGroup],
["URange", LazyURange],
["USelect", LazyUSelect],
["USelectMenu", LazyUSelectMenu],
["UTextarea", LazyUTextarea],
["UToggle", LazyUToggle],
["UTable", LazyUTable],
["UCard", LazyUCard],
["UContainer", LazyUContainer],
["UDivider", LazyUDivider],
["USkeleton", LazyUSkeleton],
["UBreadcrumb", LazyUBreadcrumb],
["UCommandPalette", LazyUCommandPalette],
["UCommandPaletteGroup", LazyUCommandPaletteGroup],
["UHorizontalNavigation", LazyUHorizontalNavigation],
["UPagination", LazyUPagination],
["UTabs", LazyUTabs],
["UVerticalNavigation", LazyUVerticalNavigation],
["UContextMenu", LazyUContextMenu],
["UModal", LazyUModal],
["UModals", LazyUModals],
["UNotification", LazyUNotification],
["UNotifications", LazyUNotifications],
["UPopover", LazyUPopover],
["USlideover", LazyUSlideover],
["USlideovers", LazyUSlideovers],
["UTooltip", LazyUTooltip],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
