import axios from 'axios'
import { useAuthApiAsyncFetch } from '@/composables/proxyApiUseFetch'

// AUTH

export function useAuthPartnerFundDocumentsSearch(fundId: string) {
  const config = useRuntimeConfig()
  const { data: authData } = useAuth()

  return axios.get(`/auth/partners/funds/${fundId}/documents/search`, {
    baseURL: config.public.proxy.apiBaseUrl,
    headers: {
      Authorization: authData.value.proxyJwt,
    },
  })
}

export async function useAuthPartnerAsyncFetch(partnerId: string) {
  return await useAuthApiAsyncFetch(`/auth/partners/${partnerId}`)
}

export async function useAuthPartnerFundsAsyncFetch(queryParams: Object) {
  return await useAuthApiAsyncFetch(`/auth/partners/funds`, {
    params: queryParams,
  })
}
