<script setup lang="ts">
const { t } = useI18n()

defineProps<{
  error: {
    statusCode: number
    statusMessage?: string
  }
}>()

const handleError = () => {
  clearError({ redirect: '/' })
}
const handleErrorFunds = () => {
  clearError({ redirect: t('routes.funds') })
}
</script>

<template>
  <NuxtLayout>
    <div class="layout-error bg-primary">
      <UContainer>
        <div
          class="min-h-[calc(100dvh_-_83.5px] flex flex-col items-center justify-center py-20"
        >
          <div class="error-img"></div>
          <h2 class="mt-20 text-42 font-bold text-white">
            {{ t('error.title') }} {{ error.statusCode }}
          </h2>
          <div class="mt-10 text-24 text-white">
            <div
              v-if="error.statusCode === 404"
              class="max-w-[550px] text-center"
            >
              <p>{{ t('error.title_404') }}</p>
              <p class="mt-10 text-p3">
                {{ t('error.text_404') }}
              </p>
            </div>

            <p v-else-if="error.statusCode === 403">{{ t('error.403') }}</p>
            <p v-else>{{ t('error.default') }}</p>
          </div>
          <div class="mt-20 flex items-center justify-center max-lg:flex-col">
            <UButton
              variant="outline"
              color="transparent"
              :label="t('error.button')"
              @click="handleError"
            />
            <UButton
              variant="outline"
              color="transparent"
              class="max-lg:mt-20 lg:ml-20"
              :label="t('error.funds')"
              @click="handleErrorFunds"
            />
          </div>
        </div>
      </UContainer>
    </div>
  </NuxtLayout>
</template>

<style lang="scss">
body {
  @apply font-opensans text-[1rem] leading-normal text-black;
}

span[class^='i-fa6-'] {
  mask-size: 100% !important;
  mask-position: center !important;
}

.layout-error {
  @media (min-width: 1280px) {
    min-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr;
  }

  + footer {
    margin-top: 0 !important;
  }
}

#CookiebotWidget {
  display: none;
}

.custom-checkbox {
  label {
    margin-top: 2px;
  }
}

.error-img {
  position: relative;
  width: 200px;
  height: 200px;
  &::before {
    content: '';
    position: absolute;
    inset: 0 auto auto 0;
    background-image: url('@/assets/images/error.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
}
</style>
