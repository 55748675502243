import _ from 'lodash'
import { getLogoutUrl as getPartnerLogoutUrl } from '~/utils/auth0/partner/methods'
import { getLogoutUrl as getInvestorLogoutUrl } from '~/utils/auth0/investor/methods'

export function getLogoutUrl(user?: Object, returnTo?: string) {
  const config = useRuntimeConfig()

  if (!returnTo) {
    returnTo = config.public.baseUrl
  }

  if (_.get(user, 'is_partner_user')) {
    return getPartnerLogoutUrl(returnTo)
  }

  return getInvestorLogoutUrl(returnTo)
}
